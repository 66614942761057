.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.is-active{
	background-color:#9fcffc;
}

.searchtext1:hover{
	background-color:#9fcffc;
}

@media (min-width: 1000px){ /*desktop*/

	.appform input[type='text']{
		width:400px;	
	}
	.datepi{
		display:flex;
		flex-direction:row;
	}
	.relationship {
		display:flex;
		flex-direction:row;
	}
	.relationship-label-num{
		display:none;
	}
	.relationship div{
		width:20% !important;
		border:0px solid blue;
	}
	.relationship input{
		width:90% !important;
		border:1px solid black;
	}
	.width3{
		width:50%;
	}
}

@media (max-width: 1000px){  /*mobile*/
	.relationship {		
	}
	.datepi{
	}
	.appform input[type='text']{
		width:100%;	
	}	
	.width3{
		width:90%;
	}
}

.cuhklogo{
	max-width:100% !important;
	width:556 !important;
	border:0px solid black !important;
}

.detailtable tr td{
	border-bottom:1px solid #E0E0E0;
	border-left:1px solid #E0E0E0;
	border-right:1px solid #E0E0E0;
	padding:10px 10px 10px 10px;
}

.detailtable tr td:nth-child(1):not([colspan='2']){
	font-weight:bold;
	background-color:#b6d1fc;
}



.Button1{
	font-size:20px;
	border:1px solid black;
	padding:4px 20px 4px 20px;
	cursor:pointer
	
}

.detailtable .delimiter{
	background-color:#ffffff !important;
	border:0px solid #ffffff !important;
}

.detailtable .detailtitle{
	text-align:center;
	font-weight:bold;	
	color:black;
	font-size:20px;
	background-color:#b6d1fc;
}

.detailtable tr td:nth-child(2){
	display:flex;
	height:100%;
	width:100%;
	flex-direction:row;
	justify-content:space-between;
}

.detailtable tr td input{	
	text-align:right;
	border:1px solid red;
	color:red;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}



*{
	font-weight:bold;
}
.App-link {
  color: #61dafb;
}


.menuitem {
	background-color:#C0C0C0;
	font-size:20px;
	padding:10px;
	border-bottom:1px solid #808080;
	cursor:pointer;
}
.menuitem:hover{
	background-color:#E0E0E0;
	font-weight:bold;
}

.function-header{
	font-size:25px;
	font-weight:bold;
}

.table1 tr td{
	padding:10px;
	padding-top:2px;
	padding-bottom:2px;
	border:1px solid #E0E0E0;
}

.smalltitle{	
	font-weight:bold;
}

.f-r{
	display:flex;
	flex-direction:row;
}

.f-r-b{
	display:flex;
	flex-direction:row;
	justify-content:space-between;
}

input[type='checkbox']{
	margin-right:5px;
}
input[type='radio']{
	margin-right:5px;
}

.multiplechoice > input{
	margin-right:0px !important;
	margin-left:0px !important;
}

.multiplechoice{
	width:10%;		
	text-align:center;
	font-weight:bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
